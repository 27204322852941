// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-in-the-news-js": () => import("./../../../src/pages/in-the-news.js" /* webpackChunkName: "component---src-pages-in-the-news-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nq-js": () => import("./../../../src/pages/nq.js" /* webpackChunkName: "component---src-pages-nq-js" */),
  "component---src-pages-nuq-factors-js": () => import("./../../../src/pages/nuq-factors.js" /* webpackChunkName: "component---src-pages-nuq-factors-js" */),
  "component---src-pages-nuq-index-js": () => import("./../../../src/pages/nuq-index.js" /* webpackChunkName: "component---src-pages-nuq-index-js" */),
  "component---src-pages-nuq-media-js": () => import("./../../../src/pages/nuq-media.js" /* webpackChunkName: "component---src-pages-nuq-media-js" */),
  "component---src-pages-nuq-scores-js": () => import("./../../../src/pages/nuq-scores.js" /* webpackChunkName: "component---src-pages-nuq-scores-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-the-company-js": () => import("./../../../src/pages/the-company.js" /* webpackChunkName: "component---src-pages-the-company-js" */),
  "component---src-pages-the-why-js": () => import("./../../../src/pages/the-why.js" /* webpackChunkName: "component---src-pages-the-why-js" */),
  "component---src-pages-what-is-nuq-js": () => import("./../../../src/pages/what-is-nuq.js" /* webpackChunkName: "component---src-pages-what-is-nuq-js" */),
  "component---src-templates-archives-js": () => import("./../../../src/templates/archives.js" /* webpackChunkName: "component---src-templates-archives-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-resources-template-js": () => import("./../../../src/templates/resources-template.js" /* webpackChunkName: "component---src-templates-resources-template-js" */)
}

